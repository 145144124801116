import React from 'react'
import './sidebar.css'
import ProfileComponent from '../../components/Sidebar/Profile/ProfileComponent'
import SidebarMenuComponent from '../../components/Sidebar/SidebarMenu/SidebarMenuComponent'

export default function sidebar() {
    return (
        <div className='sidebar position-relative background-default d-flex'>
            <div className='sidebar-title text-center p-10 mt-10'>
                <h3>SiteName</h3>
            </div>
            <ProfileComponent />
            <SidebarMenuComponent />
        </div>
    )
}
