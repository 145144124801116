import React, { useEffect, useState } from 'react'
import Wrapper from '../../../../Layout/Wrapper/Wrapper'
import MainHeaderTitleComponent from '../../../../components/Main/MainHeaderTitle/MainHeaderTitleComponent'
import CreateButtonComponent from '../../../../components/Main/CreateButton/CreateButtonComponent'
import { Button } from '@mui/material'
import { DatePicker, Upload } from 'antd'
import { axiosConfig } from '../../../../configuration/axios/axiosConfiguration'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ApiPath } from '../../../../configuration/ApiPath/ApiPath'
import { serialize } from 'object-to-formdata'
import { HelperShowMessage } from '../../../../Helpers/HelpShowMessage'
import toast from 'react-hot-toast'
import CkEditorComponent from '../../../../components/CkEditor/CkEditorComponent'
import moment from 'moment/moment'
import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'

export default function EditArticles() {

    const location = useLocation();
    const params = useParams();
    const nav = useNavigate();
    const [article, setArticle] = useState({
        image: null,
    });
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        axiosConfig.get(`/api/Categories/1/Blogs/${params.articleId}`)
            .then((res) => {
                setArticle(res.data);
                setFileList([{
                    uid: 0,
                    name: "image.jpg",
                    imgId: "1",
                    status: "done",
                    url: axiosConfig.defaults.baseURL + res.data.imagePath,
                }]);
            });
    }, []);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const SendData = async () => {
        article.image = fileList[0]?.originFileObj;
        const data = serialize(article);
        await axiosConfig.put(`${await ApiPath.articles(article.categoryId)}/${article.id}`, data).then(res => {
            HelperShowMessage(res);
            toast.success("Edited !")
            nav(-1);
        }).catch(res => {
            toast.error("Enter the values correctly")
        })
    }

    const changeText = (text) => {
        setArticle((prev) => {
            return Object.assign({}, prev, {
                text: text
            });
        });
    }

    return (
        <Wrapper>
            <div className='slider'>
                <div className='d-flex justify-between align-center mb-20'>
                    <MainHeaderTitleComponent title={`Edit article`} />
                    <CreateButtonComponent link={-1} title='Back' />
                </div>
                <div className='form p-20 background-white'>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start w-90'>Title</label>
                            <input type="text" id='title' value={article.title} placeholder='Enter Title' onChange={(e) => setArticle({ ...article, title: e.target.value })} />
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'>Short Description</label>
                            <input type="text" id='Description' placeholder='Enter Description' value={article.description} onChange={(e) => setArticle({ ...article, description: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start w-90'>Meta Title</label>
                            <input type="text" id='MetaTitle' placeholder='Enter MetaTitle' value={article.metaTitle} onChange={(e) => setArticle({ ...article, metaTitle: e.target.value })} />
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'>Meta Description</label>
                            <input type="text" id='MetaDescription' placeholder='Enter FaDescription' value={article.metaDescription} onChange={(e) => setArticle({ ...article, metaDescription: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start w-90'>Create Date</label>
                            <DatePicker
                                value={dayjs(article.registerDate)}
                                className={"width-90"}
                                onChange={(date) => {
                                    setArticle({ ...article, registerDate: date.toDate() });
                                }}
                            />
                        </div>
                    </div>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center ' style={{ marginLeft: '30px' }}>
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'>Alt Image</label>
                            <input type="text" id='ImageAlt' placeholder='Enter Alt Image' value={article.alt} onChange={(e) => setArticle({ ...article, alt: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group items-center mt-20 w-100 justify-center pl-30'>
                        <label className='text-start w-90'>Text</label>
                        <CkEditorComponent lang={"en"} data={article.text} onChangeHandler={changeText} />
                    </div>
                    <div className='text-center mt-20'>
                        <Button variant="contained" className='w-50' color="success" onClick={SendData}>
                            Edit Article
                        </Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
