import React from 'react'
import './sidebarMenu.css'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { SideBarMenuItems } from '../../../assets/Data/SideBarMenuItems'
import { NavLink } from 'react-router-dom';

export default function SidebarMenuComponent() {
    function OpenSubMenu(e) {
        let attr = e.currentTarget.getAttribute('attr');
        let el = document.querySelector("." + attr + ".sub-menu");
        let arrowIcon = document.querySelector('.' + attr + '.arrow-icon')
        if (el != null) {
            if (el.style.maxHeight) {
                el.style.maxHeight = null;
            } else {
                el.style.maxHeight = el.scrollHeight + "px";
            }
        }
        if (arrowIcon != null) {
            arrowIcon.style.transform == '' ? arrowIcon.style.transform = 'rotateZ(180deg)' : arrowIcon.style.transform = ''
        }
    }

    return (
        <div className='sidebar-menu'>
            <ul className='w-100'>
                {
                    SideBarMenuItems.map((item) => (
                        <>
                            <li key={item.id} className='menu-divider'>{item.title}</li>
                            {item.menuItems.map((menuItem) => (
                                <li key={menuItem.id} onClick={OpenSubMenu} attr={menuItem.nameEn} className='position-relative'>
                                    <NavLink to={menuItem.link} style={{ color: '#c7c7c7' }}
                                        className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                        {menuItem.icon}
                                        <span>{menuItem.name}</span>
                                        {menuItem.subMenus.length != 0 ? <MdKeyboardArrowDown className={menuItem.nameEn + ' arrow-icon'} /> : null}
                                    </NavLink>
                                    <ul className={menuItem.nameEn + ' sub-menu'}>
                                        {menuItem.subMenus.map((subMenu) => (
                                            <li className='position-relative' key={subMenu.id}>
                                                <a href="#" className=''>{subMenu.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </>
                    ))
                }

            </ul>
        </div>
    )
}
