import React, { useState } from 'react'
import Wrapper from '../../../../Layout/Wrapper/Wrapper'
import MainHeaderTitleComponent from '../../../../components/Main/MainHeaderTitle/MainHeaderTitleComponent'
import CreateButtonComponent from '../../../../components/Main/CreateButton/CreateButtonComponent'
import { Button } from '@mui/material'
import { DatePicker, Upload } from 'antd'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { axiosConfig } from '../../../../configuration/axios/axiosConfiguration'
import { useLocation, useNavigate } from 'react-router'
import { ApiPath } from '../../../../configuration/ApiPath/ApiPath'
import { serialize } from 'object-to-formdata'
import { HelperShowMessage } from '../../../../Helpers/HelpShowMessage'
import CkEditorComponent from '../../../../components/CkEditor/CkEditorComponent'
import toast from 'react-hot-toast'

export default function CreateArticles() {
    const location = useLocation();
    const [article, setArticle] = useState({
        title: '',
        metaTitle: '',
        metaDescription: '',
        categoryId: location.state.categoryId,
        text: '',
        alt: '',
        image: null,
        description: '',
        dateTime: ''
    });
    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const nav = useNavigate();


    const SendData = async () => {
        if (fileList.length == 0) return toast.error("select Image");
        article.image = fileList[0].originFileObj;
        const data = serialize(article);
        await axiosConfig.post(`${await ApiPath.articles(location.state.categoryId)}`, data).then(res => {
            HelperShowMessage(res);
            nav(-1);
        }).catch(res => {
            toast.error("Enter the values Correctly")
        })
    }

    const changeText = (text) => {
        setArticle((prev) => {
            return Object.assign({}, prev, {
                text: text
            });
        });
    }

    return (
        <Wrapper>
            <div className='slider'>
                <div className='d-flex justify-between align-center mb-20'>
                    <MainHeaderTitleComponent title={`Add article`} />
                    <CreateButtonComponent link={-1} title='Back' />
                </div>
                <div className='form p-20 background-white'>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start w-90'>Title</label>
                            <input type="text" id='title' placeholder='Enter Title' onChange={(e) => setArticle({ ...article, title: e.target.value })} />
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'>Short Description</label>
                            <input type="text" id='ShortDescription' placeholder='Enter Description' onChange={(e) => setArticle({ ...article, description: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start w-90'>Meta Title</label>
                            <input type="text" id='MetaTitle' placeholder='Enter MetaTitle' onChange={(e) => setArticle({ ...article, metaTitle: e.target.value })} />
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'>Meta Description</label>
                            <input type="text" id='faTitle' placeholder='Enter Meta Description' onChange={(e) => setArticle({ ...article, metaDescription: e.target.value })} />
                        </div>
                    </div>
                    <div className='d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label className='text-start ws-90'>Create Date</label>
                            <DatePicker
                                className={"width-90"}
                                onChange={(date) => setArticle({ ...article, createDate: date.toDate() })}     
                            />
                        </div>
                    </div>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center' style={{ marginLeft: '30px' }}>
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </div>
                        <div className='w-50'>
                            <label className='text-start w-90'> Alt Image</label>
                            <input type="text" id='faTitle' placeholder='Enter Alt Image' onChange={(e) => setArticle({ ...article, alt: e.target.value })} />
                        </div>
                    </div>
                    <div className='form-group items-center mt-20 w-100 justify-center pl-30'>
                        <label className='text-start w-90'> Text</label>
                        <CkEditorComponent lang={"en"} data={article.text} onChangeHandler={changeText} />
                    </div>
                    <div className='text-center mt-20'>
                        <Button variant="contained" className='w-50' color="success" onClick={SendData}>
                            Add Article
                        </Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
