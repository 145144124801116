import React, { useEffect, useState } from 'react'
import Wrapper from '../../../Layout/Wrapper/Wrapper'
import MainHeaderTitleComponent from '../../../components/Main/MainHeaderTitle/MainHeaderTitleComponent'
import CreateButtonComponent from '../../../components/Main/CreateButton/CreateButtonComponent'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteModalComponent from '../../../components/Main/DeleteModal/DeleteModalComponent'
import { FaEdit } from 'react-icons/fa'
import TableComponent from '../../../components/Main/Table/TableComponent'
import { Loading } from '../../../Redux/Slice/LodingSlice'
import { ApiPath } from '../../../configuration/ApiPath/ApiPath'
import { ArticlesSliceData, getArticlesListByCategoryId, removeArticlesById } from '../../../Redux/Slice/ArticelsSlice'

export default function CategoryArticles() {
    const dispatch = useDispatch();
    const param = useParams("id");
    const selector = useSelector(ArticlesSliceData);
    const nav = useNavigate();
    const [url, setUrl] = useState();
    useEffect(() => {
        dispatch(Loading({ isLoading: true }))
        getUrl()
        dispatch(getArticlesListByCategoryId({ id: param.id }))
    }, [])

    useEffect(() => {
        if (selector?.articlesList != null) dispatch(Loading({ isLoading: false }))
    }, [selector?.articlesList])

    const successCallBack = (id) => {
        dispatch(removeArticlesById({ id: id }))
    };
    const getUrl = async () => {
        setUrl(await ApiPath.articles(param.id))
    }

    let operationButtons = [];
    selector?.articlesList?.forEach(item => {
        operationButtons.push(
            <>
                <DeleteModalComponent callBack={() => successCallBack(item.id)} url={`${url}/`} name={item.id} displayName={item.title} />
                <IconButton aria-label="edit" onClick={() => nav('Edit/' + item.id, { state: { item: item, categoryId: param.id } })} color="primary">
                    <FaEdit />
                    <span>Edit</span>
                </IconButton>
            </>
        );
    });

    return (
        <Wrapper>
            <div className='d-flex justify-between align-center mb-20'>
                <MainHeaderTitleComponent title={`Article Managment`} />
                <div className="d-flex">
                    <CreateButtonComponent link='add' state={{ categoryId: param.id }} title='Add Article' />
                </div>

            </div>
            <TableComponent
                data={
                    selector.articlesList?.map(val => {
                        return {
                            title: val.title.length > 80 ? val.title.substring(0, 70) + " ..." : val.title,
                        }
                    })
                }
                columns={
                    [
                        'Index',
                        'Title',
                        'Actions'
                    ]
                }
                operationButtons={operationButtons}
            />
        </Wrapper>
    )
}
