import React from "react";
import { Link } from "react-router-dom";
import "./CreateButtonComponent.css";

export default function CreateButtonComponent(props) {
	return (
		<div className="createButton" onClick={props.onClick}>
			<Link to={props.link} state={props.state} className="transition">
				<span className="transition">{props.title}</span>
			</Link>
		</div>
	);
}
