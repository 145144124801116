import React, { useEffect, useMemo, useState } from 'react'
import './TableComponent.css'
import TableNoContent from './Pieces/TableNoContent';
import TableColumns from './Pieces/TableColumns';
import TableRow from './Pieces/TableRow';

export default function TableComponent({ data, columns, operationButtons, startIndex }) {
    let operationButtonIndex = 0;
    let i = startIndex === undefined ? 1 : startIndex;
    return (
        <div className="table-container">
            {
                data?.length > 0 ?
                    (
                        <table className="table">
                            <thead>
                                <tr>
                                    <TableColumns list={columns} />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map(
                                        (item) => {
                                            return (
                                                <>
                                                    <TableRow
                                                        key={i}
                                                        item={item}
                                                        index={i++}
                                                        operationButtons={
                                                            operationButtons !== undefined ? operationButtons[operationButtonIndex++] : null
                                                        }
                                                    />
                                                </>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    )
                    :
                    (
                        <TableNoContent />
                    )
            }
            <div className='pagination d-flex'>
                {/* <Pagination count={pageCount} page={page} onChange={pageChange} color="primary" /> */}
            </div>
        </div >
    );
}
