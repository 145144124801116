import React, { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import './navbarToggler.css'

export default function NavbarTogglerComponent() {
    const [smallSideBar, setSmallSideBar] = useState(false);

    function OpenSideBar() {
        setSmallSideBar(!smallSideBar)
        if (window.screen.width <= 1200) {
            smallSideBar == true ? document.querySelector('.sidebar').classList.add("open")
                : document.querySelector('.sidebar').classList.remove("open");
            smallSideBar == true ? document.querySelector('.sidebar-open').classList.add("open")
                : document.querySelector('.sidebar-open').classList.remove("open");

        }
    }
    useEffect(() => {
        if (window.screen.width <= 1200) {
            setSmallSideBar(true);
        }
        smallSideBar == true ? document.body.classList.add("small-sidebar") : document.body.classList.remove("small-sidebar");
    }, [smallSideBar])

    return (
        <div className='navbar-toggler' onClick={() => OpenSideBar()}>
            <a href="#" className='background-white'>
                <AiOutlineMenu />
            </a>
        </div>
    )
}
