export default function TableNoContent() {
    return (
        <div className="table-no-content">
            <h2>No Content</h2>
            <br/>
            <h1 className="sad-face">
                :(
            </h1>
        </div>
    );
}