import { CKEditor } from "@ckeditor/ckeditor5-react";
import UploadAdapter from "../../Helpers/ckEditorUploader";
import ClassicExtended from "ckeditor5-build-classic-extended";

export default function CkEditorComponent({ lang, data, onChangeHandler }) {

    function customUploadAdapterPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
        };
    }

    return (
        <CKEditor
            editor={ClassicExtended}
            data={`${data}`}
            config={{
                toolbar: {
                    items: [
                        'heading', '|',
                        'fontfamily', 'fontsize', '|',
                        'alignment', '|',
                        'fontColor', 'fontBackgroundColor', '|',
                        'bold', 'italic', 'strikethrough', 'underline', '|',
                        'link', 'imageUpload', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', 'todoList', '|',
                        'code', 'codeBlock', '|',
                        'insertTable', '|',
                        'blockQuote', '|',
                        'undo', 'redo'
                    ]
                },
                language: lang,
                extraPlugins: [customUploadAdapterPlugin],
            }}
            onChange={(event, editor) => {
                onChangeHandler(editor.getData());
            }}
        />
    )
}