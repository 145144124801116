import toast from "react-hot-toast";
import { axiosConfig } from "../configuration/axios/axiosConfiguration";
import { ApiPath } from "../configuration/ApiPath/ApiPath";

const token = "token-panel";
export const LoginAccount = (email, password) => {
    axiosConfig
        .post(`${ApiPath.identity}/login`, {
            email: email,
            password: password,
        })
        .then((res) => {
            localStorage.setItem(token, res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("name", "Admin");
            window.location.href = "/";
        })
        .catch((res) => {
            console.log(res);
            alert(res.response.data.message);
        });
};

export const RefreshToken = async (data) => {
    let response = null;
    await axiosConfig
        .post(`${ApiPath.identity}/refresh`, data)
        .then((res) => {
            toast.success(res.data.message);
            response = res.data.data;
        })
        .catch((res) => {
            toast.error(res.response.data.message);
        });
    return response;
};
