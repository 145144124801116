import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login/Login";
import { isLoggedIn } from "../Hooks/isLoggedIn";
import EditArticles from "../pages/Category/CategoryArticels/EditArticles/EditArticle";
import CreateArticles from "../pages/Category/CategoryArticels/CreateArticels/CreateArticels";
import CategoryArticles from "../pages/Category/CategoryArticels/CategoryArticels";
import Category from "../pages/Category/Category";
import CreateCategory from "../pages/Category/createCategory/CreateCategory";
import EditCategory from "../pages/Category/EditCategory/EditCategory";
import ContactUs from "../pages/ContactUs/ContactUs";
let routes = null;

if (!isLoggedIn()) {
    routes = createBrowserRouter([
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "*",
            element: <Login />,
        },
    ]);
} else {
    routes = createBrowserRouter([
        {
            path: "/",
            element: <Category />,
        },
        {
            path: "/category",
            element: <Category />,
        },
        {
            path: "/category/add",
            element: <CreateCategory />,
        },
        {
            path: "/category/edit/:id",
            element: <EditCategory />,
        },
        {
            path: "/category/:id/articles",
            element: <CategoryArticles />,
        },
        {
            path: "/category/:id/articles/Add",
            element: <CreateArticles />,
        },
        {
            path: "/category/:id/articles/Edit/:articleId",
            element: <EditArticles />,
        },
        {
            path: "/contactUs",
            element: <ContactUs />,
        },
    ]);
}
export default routes;
