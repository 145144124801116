import { IconButton } from "@mui/material";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import { axiosConfig } from "../../../configuration/axios/axiosConfiguration";

export default function DeleteModalComponent({
    name,
    url,
    displayName,
    callBack,
}) {
    function showDeleteModal() {
        Swal.fire({
            title: `Are You Sure?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes !",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const res = axiosConfig
                    .delete(url + name)
                    .then((res) => {
                        Swal.fire("Removed", "success");
                        if (callBack != null) callBack();
                    })
                    .catch((res) => {
                        Swal.fire({
                            title: res.response.data.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    });
            }
        });
    }

    return (
        <IconButton aria-label="delete" onClick={showDeleteModal} color="error">
            <AiFillDelete />
            <span>Delete</span>
        </IconButton>
    );
}
