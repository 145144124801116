import { GrArticle } from "react-icons/gr";
import {
    MdCategory,
    MdDashboard,
    MdPhone,
} from "react-icons/md";

export const SideBarMenuItems = [
    // nameEn رو باید تو کلاس icon قرار بدی
    {
        id: 1,
        title: "main",
        menuItems: [
            {
                id: 1,
                name: "Category",
                nameEn: "Category",
                icon: <MdCategory />,
                link: "/category",
                subMenus: [],
            },
            {
                id: 2,
                name: "Contact Us",
                nameEn: "Contact Us",
                icon: <MdPhone />,
                link: "/contactUs",
                subMenus: [],
            }
        ],
    },
];
