import React, { useState } from 'react'
import { RiFullscreenLine } from 'react-icons/ri'
import './navbarIcon.css'

export default function NavbarIconComponent() {
    const [fullScreen, setFullScreen] = useState(false);
    function toggleFullScreen() {
        if (fullScreen) {
            document.exitFullscreen();
        } else {
            document.body.requestFullscreen()
        }
        setFullScreen(!fullScreen);
    }

    return (
        <div className='w-20 d-flex justify-center'>
            <div className='icon mt-5 transition'>
                <RiFullscreenLine onClick={toggleFullScreen} />
            </div>
        </div>
    )
}
