import React from 'react'
import './profile.css'
import { FiUser, FiSettings } from "react-icons/fi"
import { MdExitToApp } from "react-icons/md"


export default function ProfileComponent() {

    const logOut = () => {
        localStorage.removeItem('token-panel');
        localStorage.removeItem('name');
        window.location.reload()
    }

    return (
        <div className='profile  mt-10 justify-center'>
            <div className='avatar position-relative'>
                <img src={require('../../../assets/Images/avatar/man.png')} className='w-100 h-100' alt="" />
            </div>
            <div className='avatar-info transition'>
                <h5 className='mb-10'>{localStorage.getItem("name")}</h5>
                <p>Manager</p>
                <ul className='nav d-flex'>
                    <li className='nav-item p-10' onClick={() => logOut()}>
                        <a href="#" className='background-danger transition'>
                            <MdExitToApp />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
