import React, { useEffect, useState } from "react";
import "./login.css";
import { BiUser } from "react-icons/bi";
import { MdPassword } from "react-icons/md";
import { isLoggedIn } from "../../Hooks/isLoggedIn";
import { useNavigate } from "react-router";
import { LoginAccount } from "../../service/AuthService";

export default function Login() {
    const [login, setLogin] = useState({
        email: '',
        password: '',
    })

    const nav = useNavigate();
    useEffect(() => {
        if (isLoggedIn()) nav("/dashboard");
    }, []);


    return (
        <>
            <div className="log container">
                <div className="screen">
                    <div className="screen__content">
                        <div className="login">
                            <div className="login__field">
                                <BiUser className="login__icon" />
                                <input
                                    type="text"
                                    className="login__input"
                                    placeholder="email"
                                    id="email"
                                    onChange={(e) => setLogin({ ...login, email: e.target.value })}
                                />
                            </div>
                            <div className="login__field">
                                <MdPassword className="login__icon" />
                                <input
                                    type="text"
                                    className="login__input"
                                    placeholder="Password"
                                    onChange={(e) => setLogin({ ...login, password: e.target.value })}
                                    id="pass"
                                />
                            </div>
                            <button
                                className="button login__submit"
                                onClick={() => {
                                    LoginAccount(
                                        login.email,
                                        login.password
                                    )
                                }}
                            >
                                <span className="button__text">Log In Now</span>
                                <i className="button__icon fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4"></span>
                        <span className="screen__background__shape screen__background__shape3"></span>
                        <span className="screen__background__shape screen__background__shape2"></span>
                        <span className="screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>
            </div>
        </>
    );
}
