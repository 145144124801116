import React from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./router/router";
import { Loading, LoadingSliceData } from "./Redux/Slice/LodingSlice";
import {  useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

export default function App() {
    const selector = useSelector(LoadingSliceData);

    return (
        <>
            {selector.isLoading ? <Loading /> : <></>}
            <RouterProvider router={routes} />
            <Toaster />
        </>
    );
}
