import React from 'react'
import './navbar.css'
import NavbarTogglerComponent from '../../components/Navbar/NavbarToggler/NavbarTogglerComponent'
import NavbarSearchboxComponent from '../../components/Navbar/NavbarSearchBox/NavbarSearchboxComponent'
import NavbarIconComponent from '../../components/Navbar/NavbarIcon/NavbarIconComponent'

export default function Navbar() {
    function CloseSideBar(e) {
        e.target.classList.remove('open');
        document.querySelector('.sidebar').classList.remove('open');
    }

    return (
        <div className='navbar-header d-flex'>
            <NavbarTogglerComponent />
            <div className='navbar-nav justify-end w-100 d-flex'>
                <NavbarIconComponent />
            </div>
            <div className='sidebar-open' onClick={(e) => CloseSideBar(e)}></div>
        </div>
    )
}
