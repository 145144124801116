import React, { useEffect, useState } from 'react'
import Wrapper from '../../Layout/Wrapper/Wrapper'
import MainHeaderTitleComponent from '../../components/Main/MainHeaderTitle/MainHeaderTitleComponent'
import CreateButtonComponent from '../../components/Main/CreateButton/CreateButtonComponent'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteModalComponent from '../../components/Main/DeleteModal/DeleteModalComponent'
import { FaEdit } from 'react-icons/fa'
import TableComponent from '../../components/Main/Table/TableComponent'
import { Loading } from '../../Redux/Slice/LodingSlice'
import { ApiPath } from '../../configuration/ApiPath/ApiPath'
import { CategorySliceData, getCategoryList, removeCategoryById } from '../../Redux/Slice/CategorySlice'
import { GrArticle } from 'react-icons/gr'

export default function Category() {
    const dispatch = useDispatch();
    const selector = useSelector(CategorySliceData);
    const nav = useNavigate();

    useEffect(() => {
        nav("/category")
        dispatch(Loading({ isLoading: true }))
        dispatch(getCategoryList())
    }, [])

    useEffect(() => {
        if (selector?.categoryList != null) dispatch(Loading({ isLoading: false }))
    }, [selector?.categoryList])

    const successCallBack = (id) => {
        dispatch(removeCategoryById({ id: id }))
    };

    let operationButtons = [];
    selector?.categoryList?.forEach(item => {
        operationButtons.push(
            <>
                <DeleteModalComponent callBack={() => successCallBack(item.id)} url={`${ApiPath.categories}/`} name={item.id} displayName={item.title} />
                <IconButton aria-label="edit" onClick={() => nav('Edit/' + item.id, { state: { item: item } })} color="primary">
                    <FaEdit />
                    <span>Edit</span>
                </IconButton>
                <IconButton aria-label="edit" onClick={() => nav(`${item.id}/Articles`)} color="primary">
                    <GrArticle />
                    <span>Articles</span>
                </IconButton>
            </>
        );
    });

    return (
        <Wrapper>
            <div className='d-flex justify-between align-center mb-20'>
                <MainHeaderTitleComponent title={`Category Managment`} />
                <div className="d-flex">
                    <CreateButtonComponent link='add' title='Add Category' />
                </div>

            </div>
            <TableComponent
                data={
                    selector.categoryList?.map(val => {
                        return {
                            title: val.title,
                        }
                    })
                }
                columns={
                    [
                        'Index',
                        'Name',
                        'Actions'
                    ]
                }
                operationButtons={operationButtons}
            />
        </Wrapper>
    )
}
