import React from 'react'
import './mainTitle.css'
import { SiPowerpages } from 'react-icons/si'

export default function MainHeaderTitleComponent(props) {
    return (
        <div className={'header-title d-flex ' + props.className}>
            <SiPowerpages className='mr-15' />
            <h2>{props.title}</h2>
        </div>
    )
}
