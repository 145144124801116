import { configureStore } from "@reduxjs/toolkit";
import LoadingSlice from "./Slice/LodingSlice";
import CategorySlice from "./Slice/CategorySlice";
import  ArticlesSlice from "./Slice/ArticelsSlice";

const store = configureStore({
    reducer: {
        loadingReducer: LoadingSlice,
        categoryReducer: CategorySlice,
        articlesReducer: ArticlesSlice,
    },
});

export default store;
