import { toFarsiNumber } from "../../../../Helpers/toFarsiNumber";
import { imageUrl } from "../../../../configuration/BaseUrl/BaseUrl";

export default function TableRow({ item, index, operationButtons }) {
    let table_datas = [];
	table_datas.push(<td>{index}</td>);
	Object.entries(item).forEach(([key, val]) => {
		if (key !== "id") {
			let type = typeof val;
			if (type === "string" && val != item.folderType) {
				if (
					val.endsWith("img") ||
					val.endsWith("jpg") ||
					val.endsWith("jpeg") ||
					val.endsWith("png")
				)
					table_datas.push(
						<td>
							<img
								className="img"
								src={
									imageUrl +
									"Images/" +
									item.folderType +
									"/" +
									item.id +
									"/" +
									item.image
								}
							/>
						</td>
					);
				else
					table_datas.push(
						<td>{val.trim().length === 0 ? "-" : val.trim()}</td>
					);
			} else if (type === "boolean") {
				table_datas.push(
					<td className="status w-10">
						<span className={`${val ? "danger" : "active"}`}>
						</span>
					</td>
				);
			} else if (type === "object") {
				if (val != null) table_datas.push(<td>{val.length}</td>);
				else table_datas.push(<td>-</td>);
			} else if (type === "number") {
				if (val != null)
					table_datas.push(
						<td>
							{item.price == val ? toFarsiNumber(val.toString()) : val}
						</td>
					);
				else table_datas.push(<td>-</td>);
			}
		}
	});
	return (
		<tr className="alert" role="alert" key={index}>
			{table_datas}
			{operationButtons !== null ? <td>{operationButtons}</td> : <></>}
		</tr>
	);
}
