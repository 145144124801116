import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosConfig } from "../../configuration/axios/axiosConfiguration";
import { ApiPath } from "../../configuration/ApiPath/ApiPath";

export const getCategoryList = createAsyncThunk(
    "CategorySlice/getCategoryList",
    async () => {
        const res = await axiosConfig.get(ApiPath.categories);
        return res.status == 204 ? [] : res.data;
    }
);

export const removeCategoryById = createAsyncThunk(
    "CategorySlice/removeCategoryById",
    async ({ id }) => {
        return id;
    }
);

export const CategorySlice = createSlice({
    name: "CategorySlice",
    initialState: {
        categoryList: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getCategoryList.fulfilled, (state, action) => {
            state.categoryList = action.payload;
        });
        builder.addCase(removeCategoryById.fulfilled, (state, action) => {
            state.categoryList = state.categoryList.filter(
                (p) => p.id != action.payload
            );
        });
    },
});

export const CategorySliceData = (state) => state.categoryReducer;
export default CategorySlice.reducer;
