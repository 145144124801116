import { useEffect, useState } from "react";
import Wrapper from "../../Layout/Wrapper/Wrapper";
import MainHeaderTitleComponent from "../../components/Main/MainHeaderTitle/MainHeaderTitleComponent";
import TableComponent from "../../components/Main/Table/TableComponent";
import { axiosConfig } from "../../configuration/axios/axiosConfiguration";
import { Pagination } from "@mui/material";

export default function ContactUs() {

    const [contactUsList, setContactUsList] = useState([]);
    const [page, setPage] = useState(1);
    const [contactUsCount, setContactUsCount] = useState(1);

    useEffect(() => {
        axiosConfig
            .get(`/api/Admin/ContactUsRequest?page=${page}`)
            .then((res) => {
                setContactUsList(res.data.data);
                setContactUsCount(res.data.count);
            })
    }, [page]);

    const handlePageChange = (event, value) => {
		setPage(value);
	};

    return (
        <Wrapper>
            <div className='d-flex justify-between align-center mb-20'>
                <MainHeaderTitleComponent title={`ContactUs Requests`} />
            </div>
            <TableComponent
                data={
                    contactUsList?.map(val => {
                        return {
                            firstName: val.firstName,
                            lastName: val.lastName,
                            phoneNumber: val.phoneNumber,
                            email: val.email,
                            text: val.text,
                        }
                    })
                }
                columns={
                    [
                        'Index',
                        'FirstName',
                        'LastName',
                        'PhoneNumber',
                        'Email',
                        'Text',
                    ]
                }
                startIndex={(page - 1) * 25 + 1}
            />
        <Pagination
            count={Math.ceil(contactUsCount / 25)}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            style={{
                direction: "ltr",
                display: "flex",
                justifyContent: "center",
            }}
        />
        </Wrapper>
    );
}