import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { Container } from 'react-bootstrap'
import Navbar from '../Navbar/Navbar'
import '../../Styles/form.css'

export default function Wrapper(props) {

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='main w-100'>
                <Navbar />
                <div className='main-content'>
                    <Container>
                        {props.children}
                    </Container>
                </div>
            </div>
        </div>
    )
}
