import React, { useState } from 'react'
import Wrapper from '../../../Layout/Wrapper/Wrapper'
import MainHeaderTitleComponent from '../../../components/Main/MainHeaderTitle/MainHeaderTitleComponent';
import CreateButtonComponent from '../../../components/Main/CreateButton/CreateButtonComponent';
import { Button } from '@mui/material';
import { axiosConfig } from '../../../configuration/axios/axiosConfiguration';
import { ApiPath } from '../../../configuration/ApiPath/ApiPath';
import { HelperShowMessage } from '../../../Helpers/HelpShowMessage';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

export default function CreateCategory() {
    const [category, setCategory] = useState({
        title: '',
    });
    const router = useNavigate();

    const SendData = async () => {
        await axiosConfig
            .post(ApiPath.categories, category)
            .then(response => {
                const res = HelperShowMessage(response);
                if (res === "success") return router(-1)
            }).catch(err => {
                toast.error("Enter the values Correctly")
            });
    }

    return (
        <Wrapper>
            <div className='slider'>
                <div className='d-flex justify-between align-center mb-20'>
                    <MainHeaderTitleComponent title={`Add Category`} />
                    <CreateButtonComponent link={'/category'} title='Back' />
                </div>
                <div className='form p-20 background-white'>
                    <div className='form-group d-flex items-center mt-20'>
                        <div className='w-50 text-center'>
                            <label htmlFor="" className='text-start w-90'>Title</label>
                            <input type="text" id='title' onChange={(e) => setCategory({ ...category, title: e.target.value })} placeholder='Enter Title' />
                        </div>
                        <div className='w-50 text-center mt-20'>
                            <Button variant="contained" className='w-50' color="success" onClick={SendData}>
                                Add Category
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
