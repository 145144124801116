import axios from "axios";
import Swal from "sweetalert2";
import { RefreshToken } from "../../service/AuthService";

let server = "https://api.fitbenefit.ca/";
let localhost = "http://localhost:5013";

export const axiosConfig = axios.create({
    baseURL: server,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token-panel")}`,
    },
});

axiosConfig.interceptors.response.use(null, async function (error) {
    if (error.response.status === 401) {
        if (localStorage.getItem("refreshToken") != null) {
            await RefreshToken({
                jwtToken: localStorage.getItem("token-panel"),
                refreshToken: localStorage.getItem("refreshToken"),
            })
                .then((res) => {
                    localStorage.setItem("token-panel", res.jwtToken);
                    localStorage.setItem("refreshToken", res.refreshToken);
                    window.location.reload();
                })
                .catch((res) => {
                    localStorage.clear();
                    window.location.href = "/";
                });
        }
    } else if (error.response.status === 500) {
        //window.location.href = "/500";
    }
    return Promise.reject(error);
});
