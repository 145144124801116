import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosConfig } from "../../configuration/axios/axiosConfiguration";
import { ApiPath } from "../../configuration/ApiPath/ApiPath";

export const getArticlesList = createAsyncThunk(
    "ArticlesSlice/getArticlesList",
    async () => {
        const res = await axiosConfig.get(ApiPath.categories);
        return res.status == 204 ? [] : res.data;
    }
);
export const getArticlesListByCategoryId = createAsyncThunk(
    "ArticlesSlice/getArticlesListByCategoryId",
    async ({id}) => {
        const res = await axiosConfig.get(await ApiPath.articles(id));
        return res.status == 204 ? [] : res.data.data;
    }
);

export const removeArticlesById = createAsyncThunk(
    "ArticlesSlice/removeArticlesById",
    async ({ id }) => {
        return id;
    }
);

export const ArticlesSlice = createSlice({
    name: "ArticlesSlice",
    initialState: {
        articlesList: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getArticlesList.fulfilled, (state, action) => {
            state.articlesList = action.payload;
        });
        builder.addCase(getArticlesListByCategoryId.fulfilled, (state, action) => {
            state.articlesList = action.payload;
        });
        builder.addCase(removeArticlesById.fulfilled, (state, action) => {
            state.articlesList = state.articlesList.filter(
                (p) => p.id != action.payload
            );
        });
    },
});

export const ArticlesSliceData = (state) => state.articlesReducer;
export default ArticlesSlice.reducer;
