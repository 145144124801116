import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Loading = createAsyncThunk(
    "LoadingSlice/Loading",
    async ({ isLoading }) => {
        return isLoading;
    }
);


export const LoadingSlice = createSlice({
    name: "LoadingSlice",
    initialState: {
        isLoading: false,
    },
    extraReducers: (builder) => {
        builder.addCase(Loading.fulfilled, (state, action) => {
            state.isLoading = action.payload;
        });
    },
});

export const LoadingSliceData = (state) => state.loadingReducer;
export default LoadingSlice.reducer;

