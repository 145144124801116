import toast from "react-hot-toast";

export const HelperShowMessage = (res) => {
    if (res.status === 201) {
        toast.success("Created !");
        return "success"
    } else if (res.status === 204) {
        return [];
    } else if (res.status == 200) {
        toast.success("Success !");
        return res.data;
    }
};
